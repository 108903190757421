import { Bar } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import {Loader} from '../';
import axios from 'axios';
import i18next from 'i18next';

const Chart = () => {
  const [info,setInfo]=useState();
  const [done,setDone]=useState(undefined);
  const [colors,setColors]=useState([]);
  const [colorsText,setColorsText]=useState();

  const fetchChart=async()=>{   
    const res=await axios.get(`chart.php`)
      const data=res.data;
      setInfo(data);

      const arr_colors=[];
      const arr_text=[];
      if(data){
        for(let i=0;i<data.length;i++){
          arr_colors[i]=data[i].colors;
          arr_text[i]={
            type: 'text',
            position: [data[i].name,200],
            content: data[i].value,
            style: {
              fontSize:25,
              fill: 'white',
            },
            background: {
              padding: 4.7,
              style: {
                radius: 4,
                fill: data[i].colors,
              },
            },
          }
        }
        setColors(arr_colors)
        setColorsText([...arr_text,{
          type: 'line',
          start:['start',112],
          end:['end',112],
          text:{
            content:i18next.t('content'),
            autoRotate:false,
            position:'left',
            offsetX:-45,
            offsetY:-305,
            style:{
              fontWeight:500, 
              fontSize:20,  
            }
          },
          style: {
            stroke: 'red',
            lineDash: [6, 4],
          },
        },])

        setDone(true)
      }
    }
  useEffect(()=>{
    fetchChart()
  },[])

  const config = {
    data:info,
    height:400,
    padding:50,
    xField: 'value',
    yField: 'name',
    seriesField: 'name',
    legend:false,
    barWidthRatio: 0.8,
    meta:{ value:{ max:100, tickCount:5, maxLimit:200, } },
    color: colors, 
    barBackground: { style: { fill: '#d9d9d9', } },
    annotations:colorsText,
    // label: {
    //   position: 'middle',
    //   style: {
    //     fill: '#FFFFFF',
    //     opacity: 0.9,
    //     fontSize:20,
        
    //   },
    // },
    
  };
  if(!done)return <Loader/>
  return (
    <>
      {info.length?<Bar {...config} />:null}
    </>
  );
};
export default Chart