import { useEffect,useState } from 'react';
import {Chart,Ad1,Ad2,Ad3,Ad4,Loader,Video1,Video2} from '../';
import {Card,Row,Typography,Button,Tag} from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import ErrorImage from '../../img/og-image.jpg'
import axios from 'axios';
import 'moment/locale/zh-cn';
import './Home.css';
moment().locale('zh-cn')

const {Text,Title}=Typography;

const Home = () => {
  const [article,setArticle]=useState([]);
  const [load,setLoad]=useState(25);
  const [done,setDone]=useState(undefined);
  const replaceErrorImg=e=>{
    e.target.onerror=null;
    e.target.src=ErrorImage;
  }
 
  
  const fetchData=async()=>{
      axios.get('article.php').then((response)=>{
        const articleData=response.data
        setArticle(articleData);
        setDone(true);
      })
  }
  useEffect(()=>{
    fetchData();
  },[])
  
  if (!done) return <Loader/>;
  return (
    <>
      
    
    <div className="home-page">
      <div>
        <a href="https://bbcckl.com/swnk-houze/" target="_blank" rel="noreferrer">
        <img style={{height:'auto',width:'100%',borderRadius:'0'}}src="https://ge15.orientaldaily.com.my/swinz-house.png" alt="banner"  />
      </a>
         
      </div>
      <div className='chartNP'>
        <Chart/>
      </div>
          <Ad4/>
          {article.slice(0,load).map((art,index)=>(
            <Row key={index}  >
              
              {index===7 && <Ad1/>}
              {index===14 && <Ad2/>}
              {index===21 && <Ad3/>}
              {index===2 && <Video1/> }
              {index===5 && <Video2/> }

                <Card hoverable className="article-news">
                <a href={art.url} target="_blank" rel="noreferrer">
                  <div className="news-image-container">
                      <div>
                        <img src={art.picurl} className='news-image' alt={art.id} onError={replaceErrorImg}/>
                      </div>
                      <div className='article-text'>
                        <Title className='news-title' level={5}>{art.title}</Title>
                        <Text className='news-date'>{moment(art.date).fromNow()}</Text>
                        {art.pinnews && (<Tag className="pin-news"color="red" style={{marginLeft:"1em",borderColor:'white'}}>{i18next.t("热闻")}</Tag>)}
                        <Text className='news-text' style={{marginTop:'1em'}}>{art.description.length>100 ? `${art.description.substring(0,50)}...` : art.description}</Text>
                      </div>  
                  </div>
                </a>
                </Card>
            </Row>
            )
            )
          }

    </div>
    <div className='load-page'>
      <Button className='load-button' type="primary" style={{marginBottom:'3em',backgroundColor:'#cb0c13',borderColor:'#cb0c13',height:'auto',fontSize:'15px',padding:'14px 50px'}}onClick={()=>setLoad((preLoad)=>preLoad+10)}>更多文章</Button>
    </div>
    </>
  )
}
export default Home 