import { Bar } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import {Loader} from '../';
import axios from 'axios';

const ChartNP = (props) => {
  const [info,setInfo]=useState();
  const [colors,setColors]=useState([]);
  //const [colorsText,setColorsText]=useState()
  const [done,setDone]=useState(undefined);

  const fetchChart=async()=>{   
      const res=await axios.get(`chartNP.php?state=${props.state}&code=${props.code}`)
        const data=res.data;
        setInfo(data);

        const arr_colors=[];
        //const arr_text=[];
        if(data){
          for(let i=0;i<data.length;i++){
            arr_colors[i]=data[i].colors;
            // arr_text[i]={
            //   type: 'text',
            //   position: [data[i].name,data[0].total],
            //   content: data[i].value,
            //   style: {
            //     fontSize:20,
            //     fill: 'white',
            //   },
            //   background: {
            //     padding: 5,
            //     style: {
            //       radius: 4,
            //       fillOpacity: 0.8,
            //       fill: data[i].colors,
            //     },
            //   },
            // }
          }
          setColors(arr_colors)
          //setColorsText(arr_text)
          setDone(true)
        }
      }
     
      useEffect(()=>{
        fetchChart()
      },[props.code,props.state])
  if (!done) return <Loader/>;

  const config = {
    data:info,
    height:300,
    padding:50,
    xField: 'value',
    yField: 'name',
    seriesField: 'name',
    legend:false,
    barWidthRatio: 0.9,
    meta:{ value:{ tickCount:3, maxLimit:info.length?info[0].total:0, } },
    color: colors, 
    barBackground: { style: { fill: '#d9d9d9', } },
    //annotations:colorsText,
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        //opacity: 0.9,
        fontSize:25,
      },
    },
  };
  return (
    <>
      {info.length?<Bar {...config} />:null}
    </>
  );
};
export default ChartNP